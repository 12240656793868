import React from 'react';
import "./src/assets/css/default.css";
import {createGlobalStyle} from 'styled-components';
import AOS from 'aos';
import 'aos/dist/aos.css';


const isLess1150 = typeof window !== 'undefined' && window.matchMedia('(max-width: 1150px)').matches;

AOS.init({
  once: true,
  duration: 1000, // values from 0 to 3000, with step 50ms
  disable: isLess1150,
});




const GlobalStyles = createGlobalStyle`

  [data-aos="new-animation"] {
    opacity: 0;
    transform: translate3d(0px, 13px, 0px);
    transition-property: transform, opacity;

    &.aos-animate {
      opacity: 1;
      transform: translate3d(0px, 0px, 0px);
    }
  }

  @media screen and (max-width: 1150px) {
    [data-aos] {
      pointer-events: auto !important;
      opacity: 1 !important;
      transform: none !important;
    }
  }
`;

export const wrapRootElement = ({ element }) => (
  <div>
    <GlobalStyles />
    {element}
  </div>
);
